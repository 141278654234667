import styled from 'styled-components'

const BigHeading = styled.h1`
  font-weight: 800;
  text-transform: uppercase;
  color: ${props => (props.dark ? '#0a0a0a' : '#ffffff')};
  -webkit-font-smoothing: ${props => (props.dark ? 'initial' : 'antialiased')};
  -moz-osx-font-smoothing: ${props => (props.dark ? 'initial' : 'grayscale')};
  @media (max-width: 768px) {
    font-size: 36px;
  }
  @media (min-width: 769px) {
    font-size: 48px;
  }
`

export default BigHeading
