import React from 'react'
import styled from 'styled-components'

const InlineVideo = ({ src, ...props }) => (
  <Wrapper {...props}>
    <StyledIFrame src={src} frameBorder="0" allowFullScreen />
  </Wrapper>
)

const Wrapper = styled('div')`
  overflow: hidden;
  position: relative;
`

const StyledIFrame = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default InlineVideo
