import styled from 'styled-components'

const ForegroundLayout = styled.div`
  position: relative;
  z-index: 1;
  height: ${props => (props.useNaturalHeight ? 'unset' : '100%')};
  /* max-width: 80vw; */
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 90%;
    margin-right: 5% !important;
    margin-left: 5% !important;
  }
  @media (min-width: 769px) and (max-width: 1366px) {
    width: 86%;
    margin-right: 7% !important;
    margin-left: 7% !important;
  }
  @media (min-width: 1367px) {
    width: 70vw;
    /* max-width: 1500px; */
    margin-right: auto !important;
    margin-left: auto !important;
  }
  -webkit-font-smoothing: ${props =>
    props.lightOnDark ? 'antialiased' : 'initial'};
  -moz-osx-font-smoothing: ${props =>
    props.lightOnDark ? 'grayscale' : 'initial'};
`

export default ForegroundLayout
