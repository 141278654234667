import { useState, useEffect } from 'react'

export default function useScrollOffset() {
  const initialState = { x: 0, y: 0 }
  const [offset, setOffset] = useState(initialState)
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const handleScroll = () =>
      setOffset({ x: window.scrollX, y: window.scrollY })
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })
  return offset
}
