import React from 'react'
import styled from 'styled-components'

import useScrollOffset from '../hooks/useScrollOffset'
import NakedLink from './NakedLink'
import FlexBox from './FlexBox'
import NavigationMenu from './NavigationMenu'
import OrangeAstronautHelmet from '../graphics/justthehead.svg'

function Header({ alwaysShowOverlay, menuItems, location }) {
  const scrollOffset = useScrollOffset()
  const isInFlight =
    typeof window !== 'undefined' ? scrollOffset.y > window.innerHeight : false
  return (
    <StyledHeader withOverlay={alwaysShowOverlay || isInFlight}>
      <FlexBox
        flexDirection="row"
        justifyContent="flex-start"
        itemSpacing="1rem"
        css={`
          padding: 1rem;
        `}
      >
        {menuItems && menuItems.length > 0 && (
          <NavigationMenu items={menuItems} location={location} />
        )}
        <NakedLink to="/">
          <FlexBox flexDirection="row" alignItems="center" itemSpacing="1rem">
            <LogoWrapper size={isInFlight ? '35px' : '50px'}>
              <OrangeAstronautHelmet
                css={`
                  color: #0a0a0a;
                `}
              />
            </LogoWrapper>
            <StyledName />
          </FlexBox>
        </NakedLink>
      </FlexBox>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  z-index: 10;
  // padding: 1rem;
  // transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  // background-color: ${props =>
    props.withOverlay ? 'rgba(0, 0, 0, 0.8)' : 'transparent'};
  @media (max-width: 768px) {
    // background-color: rgba(0, 0, 0, 0.8);
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    z-index: -1;
    width: 2000px;
    height: 1000px;
    transform: skewY(-5.7deg);
    background-color: rgba(0, 0, 0, 0.8);
    @media (max-width: 768px) {
      bottom: 70px;
    }
    @media (min-width: 769px) {
      bottom: 60px;
    }
  }
`

const LogoWrapper = styled.div`
  width: ${props => props.size || '70px'};
  transition: width 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  @media (max-width: 768px) {
    width: 40px;
  }
`

const StyledName = () => (
  <h1
    css={`
      @media (max-width: 768px) {
        font-size: 20px;
      }
      @media (min-width: 769px) {
        font-size: 24px;
      }
      margin: 0;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `}
  >
    <span
      css={`
        font-weight: 700;
        color: #f93616;
      `}
    >
      Orange{' '}
    </span>
    <span
      css={`
        font-weight: 300;
        color: #ffffff;
      `}
    >
      Astronaut
    </span>
  </h1>
)

export default Header
