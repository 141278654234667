import PropTypes from 'prop-types'
import styled from 'styled-components'

const FlexBox = styled.div`
  display: flex;
  width: ${props => (props.useFlexWidth ? 'unset' : '100%')};
  height: ${props => (props.useFlexHeight ? 'unset' : '100%')};
  @media (max-width: ${props => String(props.breakPoint || 768) + 'px'}) {
    flex-direction: ${props =>
      props.isResponsive ? 'column' : props.flexDirection};
    & > *:not(:last-child) {
      margin-right: ${props => {
        const flexDirection = props.isResponsive
          ? 'column'
          : props.flexDirection
        if (flexDirection === 'row') return props.itemSpacing || 0
        else return 0
      }};
      margin-bottom: ${props => {
        const flexDirection = props.isResponsive
          ? 'column'
          : props.flexDirection
        if (flexDirection === 'column') return props.itemSpacing || 0
        else return 0
      }};
    }
  }
  @media (min-width: ${props =>
    String(props.breakPoint ? props.breakPoint + 1 : 769) + 'px'}) {
    flex-direction: ${props => props.flexDirection};
    & > *:not(:last-child) {
      margin-right: ${props =>
        props.flexDirection === 'row' ? props.itemSpacing || 0 : 0};
      margin-bottom: ${props =>
        props.flexDirection === 'column' ? props.itemSpacing || 0 : 0};
    }
  }
  // control how children align perpendicular to the axis (flex direction)
  align-items: ${props => props.alignItems || 'normal'};
  // control how children distribute along the axis (flex direction)
  justify-content: ${props => props.justifyContent || 'stretch'};
  // control flex value for all children
  // (could be improved to support nth child flex)
  /* & > * {
    flex: ${props => props.childFlex || 'initial'};
  } */
`

FlexBox.propTypes = {
  flexDirection: PropTypes.oneOf(['row', 'column']).isRequired,
  alignItems: PropTypes.oneOf([
    'normal',
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline'
  ]),
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
    'stretch'
  ]),
  itemSpacing: PropTypes.string, // ideally use a custom validator for units
  isResponsive: PropTypes.bool
}

export default FlexBox
