import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import Modal from 'react-modal'
import 'sanitize.css'

import Header from './Header'
import Footer from './Footer'
import BigHeading from './BigHeading'
import MediumHeading from './MediumHeading'
import Paragraph from './Paragraph'
import '../styles/fonts.css'

const menuItems = [
  { label: 'Our Process', path: '/branding' },
  { label: 'Services', path: '/services' },
  { label: 'Work', path: '/work' },
  { label: 'About', path: '/about' }
]

const mdxComponents = {
  h1: BigHeading,
  h2: MediumHeading,
  p: Paragraph
}

Modal.setAppElement('#___gatsby')

const PageWrapper = ({ children, location, pageContext }) => {
  if (pageContext.isLanding) {
    return (
      <React.Fragment>
        <GlobalStyle />
        <Header
          alwaysShowOverlay={routesWithBarOverlay.includes(location.pathname)}
          location={location}
        />
        <main>{children}</main>
      </React.Fragment>
    )
  } else {
    return (
      <MDXProvider components={mdxComponents}>
        <GlobalStyle />
        <Header
          menuItems={menuItems}
          alwaysShowOverlay={routesWithBarOverlay.includes(location.pathname)}
          location={location}
        />
        <main>{children}</main>
        <Footer />
      </MDXProvider>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'canada-type-gibson', sans-serif;
    background-color: #0a0a0a;
  }
  h1 {
    font-family: 'Prompt', sans-serif;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .ReactModal__Overlay--after-open{
      opacity: 1;
  }
  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  .gatsby-resp-image-image {
    width: 100%;
    height: 100%;
    margin: 0
    vertical-align: middle;
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: inset 0px 0px 0px 400px white;
  }
`

const routesWithBarOverlay = ['/terms/', '/terms', '/privacy/', '/privacy']

export default PageWrapper
