import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import ForegroundLayout from './ForegroundLayout'
import FlexBox from './FlexBox'
import SocialMediaLink from './SocialMediaLink'

const FooterHeading = styled.span`
  padding-bottom: 1rem;
  font-style: normal;
  font-size: 20px;
  font-family: 'Prompt', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const FooterText = styled.span`
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const FooterAnchor = styled.a`
  display: inline-block;
  margin-top: -8px;
  padding: 8px 0;
  color: #f93616;
  text-decoration: none;
`

const Footer = () => (
  <Wrapper>
    <ForegroundLayout>
      <FlexBox flexDirection="column" itemSpacing="2rem">
        <FlexBox
          flexDirection="row"
          justifyContent="space-between"
          itemSpacing="2rem"
          isResponsive
        >
          <FlexBox
            as="address"
            flexDirection="row"
            justifyContent="flex-start"
            itemSpacing="4rem"
            isResponsive
          >
            <FlexBox flexDirection="column" itemSpacing="0.5rem" useFlexWidth>
              <FooterHeading>Tracking...</FooterHeading>
              <FooterText>116 Smithfield Street</FooterText>
              <FooterText>Floor Four</FooterText>
              <FooterText>Pittsburgh, PA 15222</FooterText>
            </FlexBox>
            <FlexBox flexDirection="column" itemSpacing="0.5rem" useFlexWidth>
              <FooterHeading>Contact</FooterHeading>
              <FooterText>
                <FooterAnchor href="mailto:contact@orangeastronaut.com">
                  contact@orangeastronaut.com
                </FooterAnchor>
              </FooterText>
            </FlexBox>
          </FlexBox>
          <FlexBox
            flexDirection="row"
            itemSpacing="2rem"
            useFlexWidth
            css={`
              flex: none;
            `}
          >
            {socialMediaLinks.map((link, index) => (
              <SocialMediaLink
                key={index}
                url={link.url}
                color={'rgba(255, 255, 255, 0.5)'}
              />
            ))}
          </FlexBox>
        </FlexBox>
        <SmallPrint>
          {legalLinks.map((link, index) => (
            <StyledLink key={index} to={link.url}>
              {link.title}
            </StyledLink>
          ))}
          <Copyright>
            © 2019 Orange Astronaut, LLC. All rights reserved.
          </Copyright>
        </SmallPrint>
      </FlexBox>
    </ForegroundLayout>
  </Wrapper>
)

export default Footer

const Wrapper = styled.div`
  padding: 4rem 0;
  width: 100%;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`

const SmallPrint = styled(props => (
  <FlexBox flexDirection="row" itemSpacing="1rem" isResponsive {...props} />
))`
  & > * {
    display: inline-block;
  }
  & a {
    font-size: 16px;
  }
`

const StyledLink = styled(Link)`
  color: #888;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: color 150ms linear;
  &:hover {
    color: #ddd;
  }
`

const Copyright = styled.p`
  display: inline-block;
  margin: 0;
  color: #888;
  font-weight: 400;
`

const socialMediaLinks = [
  {
    title: 'Orange Astronaut Vimeo',
    url: 'https://vimeo.com/orangeastronaut'
  },
  {
    title: 'Orange Astronaut Facebook',
    url: 'https://www.facebook.com/creativeagencypgh'
  },
  {
    title: 'Orange Astronaut LinkedIn',
    url: 'https://www.linkedin.com/company/orangeastronaut'
  }
]

const legalLinks = [
  {
    title: 'Terms of Use',
    url: '/terms'
  },
  {
    title: 'Privacy Policy',
    url: '/privacy'
  }
]
