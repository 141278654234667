import styled from 'styled-components'

const Paragraph = styled.p`
  line-height: 1.5;
  font-weight: 300;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 768px) {
    font-size: 22px;
  }
  @media (min-width: 769px) {
    font-size: 26px;
  }
`

export default Paragraph
