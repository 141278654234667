// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-work-js": () => import("/Users/tim/code/orange-astronaut-website/src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-pages-404-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brand-launch-strategy-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/brand-launch-strategy.js" /* webpackChunkName: "component---src-pages-brand-launch-strategy-js" */),
  "component---src-pages-branding-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-hello-serendipity-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/hello-serendipity.js" /* webpackChunkName: "component---src-pages-hello-serendipity-js" */),
  "component---src-pages-index-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-index-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-video-library-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/services/video/library.js" /* webpackChunkName: "component---src-pages-services-video-library-js" */),
  "component---src-pages-terms-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-work-js": () => import("/Users/tim/code/orange-astronaut-website/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

