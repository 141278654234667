import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Modal from 'react-modal'
import styled from 'styled-components'

import FlexBox from './FlexBox'
import MenuIcon from '../graphics/menu.svg'
import CloseIcon from '../graphics/close.svg'

function NavigationMenu({ items, location }) {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(false)
  }, [location.key])
  return (
    <React.Fragment>
      <Button onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </Button>
      <Menu
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick={true}
      >
        <FlexBox
          as={MenuList}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          itemSpacing="1vw"
        >
          {items.map((item, index) => (
            <MenuItem key={index}>
              <StyledLink to={item.path}>{item.label}</StyledLink>
            </MenuItem>
          ))}
        </FlexBox>
      </Menu>
    </React.Fragment>
  )
}

const Button = styled.button`
  border: 0;
  cursor: pointer;
  background-color: transparent;
`

function ModalAdapter({ children, className, ...props }) {
  return (
    <Modal
      overlayClassName={className}
      className={`${className}__content`}
      {...props}
    >
      {children}
    </Modal>
  )
}

const Menu = styled(ModalAdapter)`
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    position: relative;
    background-color: transparent;
    outline: none;
  }
`

const MenuList = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0;
`

const MenuItem = styled.li`
  display: block;
`

const StyledLink = styled(Link)`
  display: inline-block;
  height: 100%;
  padding: 0.2rem 0.8rem 0.1rem;
  position: relative;
  text-decoration: none;
  color: #f93616;
  font-family: 'Prompt', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: width 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover {
    color: #0a0a0a;
  }
  &:hover ::after {
    width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (min-width: 769px) {
    font-size: 3rem;
  }
`

export default NavigationMenu
