module.exports = [{
      plugin: require('/Users/tim/code/orange-astronaut-website/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":5000,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('/Users/tim/code/orange-astronaut-website/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/tim/code/orange-astronaut-website/src/components/PageWrapper.js"},
    },{
      plugin: require('/Users/tim/code/orange-astronaut-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Orange Astronaut","short_name":"OA","start_url":"/","background_color":"#0a0a0a","theme_color":"#f93616","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('/Users/tim/code/orange-astronaut-website/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-123355294-1","head":false,"anonymize":true},
    },{
      plugin: require('/Users/tim/code/orange-astronaut-website/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"5857151","respectDNT":true},
    },{
      plugin: require('/Users/tim/code/orange-astronaut-website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"pki4qss"}},
    },{
      plugin: require('/Users/tim/code/orange-astronaut-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
